import type { PaywallModel } from "../../models/paywall.model";
import { RegularLinkButton } from "@/components/Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "@/components/Card/Card.component";
import { Divider } from "@/components/Divider/Divider.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending } from "@/services/utilities/fetchable";
import clsx from "clsx";
type CorporateCardProps = {
  readonly apps: null | PaywallModel.Apps;
  readonly url: string;
  readonly variant?: "outline" | "primary";
  readonly state?: Fetchable;
};
export function CorporateCard({
  state = initial(),
  url,
  variant = "primary"
}: CorporateCardProps) {
  return <Card className="flex h-full flex-col justify-between gap-y-3 rounded border-silver text-center lg:px-4" data-sentry-element="Card" data-sentry-component="CorporateCard" data-sentry-source-file="CorporateCard.components.tsx">
      <h1 className={clsx("hyphens-manual break-words text-xl font-bold leading-tight")}>
        <Translation da="Virksomheds&shy;abonnement" de="Team- und unternehmens&shy;weite Lizenz" en="Corporate subscription" no="Bedrifts&shy;abonnement" sv="Företags&shy;abonnemang" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </h1>
      <Divider className="relative" orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="CorporateCard.components.tsx" />
      <p className="text-left">
        <Translation da="Med et virksomhedsabonnement får du:" de="Mit einem Business-Abonnement erhalten Sie:" en="With a corporate subscription you get:" no="Med et bedriftsabonnement får du:" sv="Med ett företagsabonnemang får du:" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </p>
      <List className="gap-1 text-left" type="checkmark" data-sentry-element="List" data-sentry-source-file="CorporateCard.components.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Alle fordele fra et personligt abonnement" de="Vorteile Ihrer persönlichen Lizenz" en="All the benefits of a personal subscription" no="Alle fordelene fra våre personlige abonnementer" sv="Alla fördelar med en personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Løsning tilpasset din virksomheds behov" de="Lösung, die auf die Bedürfnisse Ihres Unternehmens zugeschnitten ist" en="Solution tailored to your company's needs" no="Løsning tilpasset din bedrifts behov" sv="Lösning anpassad till ditt företags behov" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateCard.components.tsx">
          <Translation da="Attraktiv pris per bruger" de="Attraktiver Preis pro Benutzer" en="Attractive price per user" no="Attraktiv pris per bruker" sv="Attraktivt pris per användare" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
        </ListItem>
      </List>

      <RegularLinkButton disabled={isPending(state)} href={url} position="pw-co-bbtn" variant={variant} data-sentry-element="RegularLinkButton" data-sentry-source-file="CorporateCard.components.tsx">
        <Translation da="Bestil adgang" de="Zugang bestellen" en="Order access" no="Bestill tilgang" sv="Beställ åtkomst" data-sentry-element="Translation" data-sentry-source-file="CorporateCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}