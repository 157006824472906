import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { Link } from "@/components/Link/Link.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { clsx } from "clsx";
type RelatedArticlesProps = {
  readonly className?: string;
  readonly relatedArticles: ReadonlyArray<ArticleTeaserModel.RelatedArticle>;
  readonly isInverted?: boolean;
  readonly type?: "bullet";
  readonly kickerClassName?: string;
};
export function RelatedArticles({
  className,
  isInverted = false,
  kickerClassName,
  relatedArticles,
  type
}: RelatedArticlesProps) {
  return <List className={clsx(className, "space-y-2 text-sm")} tag="section" type={type} data-sentry-element="List" data-sentry-component="RelatedArticles" data-sentry-source-file="RelatedArticles.component.tsx">
      {relatedArticles.map(({
      targetUrl,
      title
    }, index) => <ListItem className={clsx(isInverted && "marker:text-inherit")} key={targetUrl} position={`rart${index + 1}`} tag="article">
          <Link className="hover:underline" href={targetUrl}>
            <>
              {title.kicker !== null ? <span className={kickerClassName}>{title.kicker} </span> : null}
              <span dangerouslySetInnerHTML={{
            __html: title.html
          }} />
            </>
          </Link>
        </ListItem>)}
    </List>;
}