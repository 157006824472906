"use client";

import type { ArticleContentModel } from "../../models/article-content-model.types";
import { Link } from "@/components/Link/Link.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { imageLoader } from "@/utilities/image-loader";
import NextImage from "next/image";
type RelatedArticleTeaserProps = {
  readonly model: ArticleContentModel.RelatedArticle;
};
export function RelatedArticleTeaser({
  model: {
    image,
    kicker,
    requiresSubscription,
    targetUrl,
    title
  }
}: RelatedArticleTeaserProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <article className="grid grid-cols-4 gap-x-2 gap-y-1 xl:grid-cols-6" data-sentry-component="RelatedArticleTeaser" data-sentry-source-file="RelatedArticleTeaser.component.tsx">
      <div className="col-span-1 empty:hidden">
        {image !== null ? <Link className="relative row-span-full block aspect-3/2 w-full" href={targetUrl}>
            <NextImage alt={image.alternativeText} className="bg-whisper object-cover" fill loader={imageLoader(serverUrl)} sizes="172px" src={image.aspectRatio4By3} />
          </Link> : null}
      </div>
      <div className="col-span-3 xl:col-span-5">
        <Link className="block w-fit hover:underline" href={targetUrl} data-sentry-element="Link" data-sentry-source-file="RelatedArticleTeaser.component.tsx">
          <h1 className="hyphens-manual break-words leading-snug">
            {kicker} {title}
          </h1>
        </Link>
        {requiresSubscription ? <RequiresSubscriptionLabel className="mt-1 text-primary" /> : null}
      </div>
    </article>;
}