"use client";

import { captureClientException } from "../../../services/logging/capture-client-exception";
import { fetchUser } from "@/app/_api/services/fetch-user";
import { addTrial } from "@/app/(sites)/_containers/_actions/add-trial";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, succeeded } from "@/services/utilities/fetchable";

export function useAddTrial() {
  return {
    addTrialToCurrentProfile,
  };
}

async function addTrialToCurrentProfile(
  articleId: null | string,
): Promise<Fetchable> {
  const isUserNameValid =
    (await userHasFirstAndLastName().catch(captureClientException)) === true;

  if (!isUserNameValid) {
    return failed("ADD_TRIAL_INVALID_NAME");
  }

  const result = await addTrial({
    articleId,
  });

  return result.status !== "CREATED" ? failed(result.status) : succeeded();
}

async function userHasFirstAndLastName(): Promise<boolean> {
  const { user } = await fetchUser();

  if (!user) return false;

  return user.firstName.trim() !== "" && user.lastName.trim() !== "";
}
