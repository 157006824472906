"use client";

import { withDebounce } from "@/utilities/with-debounce";
import type { RefObject } from "react";
import { useEffect, useState } from "react";

const debounceDelay = 15;

const roundToNearestDefault = 0.1;

type UseScrollPointsReturn = {
  readonly percentageViewed: number;
};

type UseElementViewedPercentageProps = {
  readonly ref: RefObject<HTMLElement>;
  readonly roundToNearest?: number;
};

export function useElementViewedPercentage({
  ref,
  roundToNearest = roundToNearestDefault,
}: UseElementViewedPercentageProps): UseScrollPointsReturn {
  const [percentageViewed, setPercentageViewed] = useState<number>(0);
  const oneHundred = 100;

  useEffect(() => {
    const debounceUpdatePercentageViewed = withDebounce(
      updatePercentageViewed,
      debounceDelay,
    );
    updatePercentageViewed();

    window.addEventListener("scroll", debounceUpdatePercentageViewed, {
      passive: true,
    });

    function updatePercentageViewed() {
      if (ref.current === null) return;
      const currentScrollPercentage = getCurrentScrollPercentage();

      if (
        percentageViewed < currentScrollPercentage &&
        currentScrollPercentage > 0
      ) {
        setPercentageViewed(currentScrollPercentage);
      }

      if (currentScrollPercentage > oneHundred) {
        setPercentageViewed(oneHundred);
      }
    }

    function getCurrentScrollPercentage(): number {
      if (ref.current === null) return 0;

      const elementHeight = ref.current.offsetHeight;
      const elementRect = ref.current.getBoundingClientRect();
      const percentage =
        ((window.screen.height - elementRect.top) / elementHeight) * oneHundred;
      const roundedPercentage =
        Math.round(percentage / roundToNearest) * roundToNearest;

      return roundedPercentage;
    }

    return function cleanUp() {
      window.removeEventListener("scroll", debounceUpdatePercentageViewed);
    };
  }, [ref, percentageViewed, roundToNearest]);

  return {
    percentageViewed,
  };
}
