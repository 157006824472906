"use client";

import type { PaywallModel } from "../../models/paywall.model";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { Spinner } from "@/components/Spinner/Spinner.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { hasFailed, initial, isPending } from "@/services/utilities/fetchable";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import type { ReactNode } from "react";
type PaywallAddTrialButtonProps = {
  readonly articleId: null | string;
  readonly state?: Fetchable;
  readonly trial: PaywallModel.Trial;
  readonly addTrialComponent?: ReactNode;
};
export function PaywallAddTrialLink({
  addTrialComponent = null,
  state = initial(),
  trial
}: PaywallAddTrialButtonProps) {
  const {
    name: siteName
  } = useSite();
  return <div className="m-auto inline-flex flex-col border-b border-solid border-silver pb-2 text-center text-sm md:text-base" data-sentry-component="PaywallAddTrialLink" data-sentry-source-file="PaywallAddTrialLink.component.tsx">
      <p className="flex-auto">
        <Translation da={inlineJsx`Prøv ${siteName} i ${trial.numberOfFreeTrialDays} dage - og få adgang til alt indhold.`} de={inlineJsx`Testen Sie ${siteName} ${trial.numberOfFreeTrialDays} Tage lang - und erhalten Sie Zugang zu allen Inhalten.`} en={inlineJsx`Try ${siteName} for ${trial.numberOfFreeTrialDays} days - and get access to all content.`} no={inlineJsx`Prøv ${siteName} i ${trial.numberOfFreeTrialDays} dager - og få tilgang til alt innhold.`} sv={inlineJsx`Prova ${siteName} i ${trial.numberOfFreeTrialDays} dagar - och få tillgång till allt innehåll.`} data-sentry-element="Translation" data-sentry-source-file="PaywallAddTrialLink.component.tsx" />
      </p>

      {!isPending(state) ? addTrialComponent : null}

      {isPending(state) ? <div className="flex justify-center">
          <Spinner className="h-3 w-3 rounded-full fill-primary text-whisper group-disabled:fill-silver" />
        </div> : null}

      {hasFailed(state) ? <ErrorMessage status={state.errorMessage as ErrorMessageStatus} /> : null}
    </div>;
}