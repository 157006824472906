"use client";

import type { CommonButtonProps } from "@/components/Button/regular-button.types";
import { Link } from "@/components/Link/Link.component";
import type { LinkProps } from "@/components/Link/Link.component";
import { useUser } from "@/contexts/user/user.context";
import type { ReactNode } from "react";
type LoginLink = Pick<CommonButtonProps, "position"> & {
  readonly className?: string;
  readonly children: ReactNode;
  readonly redirectTo?: string;
  readonly variant?: LinkProps["variant"];
};
export function LoginLink({
  children,
  className,
  position,
  redirectTo,
  variant = null
}: LoginLink) {
  const {
    urls: {
      createLoginUrl,
      loginAndReturnUrl
    }
  } = useUser();
  return <Link className={className} href={redirectTo !== undefined ? createLoginUrl(redirectTo) : loginAndReturnUrl} openInNewTab={false} position={position} variant={variant} data-sentry-element="Link" data-sentry-component="LoginLink" data-sentry-source-file="LoginLink.component.tsx">
      {children}
    </Link>;
}