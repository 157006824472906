"use client";

import { ShareButtons } from "./ShareButtons.component";
import { useSite } from "@/contexts/site/site.context";
import { useState, useEffect } from "react";
type ShareButtonsContainerProps = {
  readonly className?: string;
  readonly title: string;
};
export function ShareButtonsContainer({
  className,
  title
}: ShareButtonsContainerProps) {
  const {
    domain
  } = useSite();
  const [encodedArticleUrl, setEncodedArticleUrl] = useState<string>(`https://${domain}`);
  useEffect(() => setEncodedArticleUrl(window.location.href), []);
  return <ShareButtons className={className} model={{
    encodedUrl: encodedArticleUrl,
    title
  }} data-sentry-element="ShareButtons" data-sentry-component="ShareButtonsContainer" data-sentry-source-file="ShareButtonsContainer.component.tsx" />;
}