"use client";

import type { ArticleContentModel } from "../../models/article-content-model.types";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { imageLoader } from "@/utilities/image-loader";
import clsx from "clsx";
import NextImage from "next/image";
type SupportingImageProps = {
  readonly image: ArticleContentModel.SupportingImage;
  readonly caption: null | string;
  readonly ratio?: "16:9" | "4:3";
  readonly sourceSetSize: string;
};
export function SupportingImage({
  caption,
  image,
  ratio = "4:3",
  sourceSetSize
}: SupportingImageProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <figure data-sentry-component="SupportingImage" data-sentry-source-file="SupportingImage.tsx">
      <div className={clsx([`relative w-full`, ratio === "4:3" ? "aspect-4/3" : "aspect-16/9"])}>
        <NextImage alt={caption ?? ""} className="bg-whisper object-cover" fill loader={imageLoader(serverUrl)} priority sizes={sourceSetSize} src={ratio === "4:3" ? image.aspectRatio4By3 : image.aspectRatio16By9} data-sentry-element="NextImage" data-sentry-source-file="SupportingImage.tsx" />
      </div>
      {caption !== null ? <figcaption className="pt-1 text-sm text-dim">{caption}</figcaption> : null}
    </figure>;
}