import { clsx } from "clsx";
import type { ReactElement } from "react";
import { Children, cloneElement } from "react";
type ListProps = {
  readonly className?: string;
  readonly children: ReactElement | ReadonlyArray<null | ReactElement>;
  readonly position?: string;
  readonly type?: "bullet" | "checkmark";
  readonly tag?: keyof JSX.IntrinsicElements;
};
export function List({
  children,
  className,
  position,
  tag = "ul",
  type
}: ListProps) {
  const TagComponent = tag;
  const renderChildren = () => Children.map(children, child => {
    if (child === null) return null;
    return cloneElement(child, {
      type
    });
  });
  return <TagComponent className={clsx(className, "flex flex-col")} data-k5a-pos={position} data-sentry-element="TagComponent" data-sentry-component="List" data-sentry-source-file="List.component.tsx">
      {renderChildren()}
    </TagComponent>;
}