"use client";

import { ConsumerCard } from "./_components/ConsumerCard/ConsumerCard.components";
import { CorporateCard } from "./_components/CorporateCard/CorporateCard.components";
import { PaywallLayout } from "./_components/PaywallLayout/PaywallLayout.component";
import { PaywallLoginNotification } from "./_components/PaywallLoginNotification/PaywallLoginNotification.component";
import { PaywallTermsLink } from "./_components/PaywallTermsLink/PaywallTermsLink.component";
import { PaywallTitle } from "./_components/PaywallTitle/PaywallTitle.component";
import { TrialCard } from "./_components/TrialCard/TrialCard.component";
import type { PaywallModel } from "./models/paywall.model";
import { useSite } from "@/contexts/site/site.context";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
type UnauthenticatedTrialFocusPaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly redirectUrl: string;
};
export function UnauthenticatedTrialFocusPaywall({
  className,
  model,
  redirectUrl
}: UnauthenticatedTrialFocusPaywallProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const {
    name: siteName,
    subscription: {
      trialDays
    }
  } = useSite();
  const {
    apps,
    articleId,
    product,
    termsAndConditionsUrl,
    trial
  } = model;
  const isTrialCardVisible = trial !== null && !trial.isUserTrialExpired && trial.numberOfFreeTrialDays > 0;
  const notLoggedInTitleTranslation = isTrialCardVisible ? {
    da: `Prøv ${siteName} gratis i ${trialDays} dage`,
    de: `Testen Sie ${siteName} ${trialDays} Tage lang kostenlos`,
    en: `Try ${siteName} for free for ${trialDays} days`,
    no: `Prøv ${siteName} gratis i ${trialDays} dager`,
    sv: `Prova ${siteName} gratis i ${trialDays} dagar`
  } : {
    da: `Køb abonnement og få adgang til hele ${siteName}`,
    de: `Abonnement kaufen und Zugang zu ${siteName} erhalten`,
    en: `Buy a subscription and get access to all of ${siteName}`,
    no: `Kjøp abonnement og få tilgang til hele ${siteName}`,
    sv: `Köp en prenumeration och få tillgång till hela ${siteName}`
  };
  const onAddTrialLinkClick = () => {
    const searchParamsString = searchParams.toString();
    const url = `/profile/create/trial?redirectUrl=${encodeURIComponent(`${pathname}${searchParamsString ? `?${searchParamsString}` : ""}`)}`;
    router.push(url);
  };
  return <PaywallLayout className={className} data-sentry-element="PaywallLayout" data-sentry-component="UnauthenticatedTrialFocusPaywall" data-sentry-source-file="UnauthenticatedTrialFocusPaywall.component.tsx">
      <PaywallLoginNotification data-sentry-element="PaywallLoginNotification" data-sentry-source-file="UnauthenticatedTrialFocusPaywall.component.tsx" />
      <PaywallTitle model={notLoggedInTitleTranslation} data-sentry-element="PaywallTitle" data-sentry-source-file="UnauthenticatedTrialFocusPaywall.component.tsx" />

      {isTrialCardVisible ? <TrialCard model={{
      siteName,
      trialDays: trial.numberOfFreeTrialDays
    }} onClick={onAddTrialLinkClick} /> : null}

      <ConsumerCard model={{
      apps,
      product,
      siteName,
      url: `/shop/consumer?articleId=${articleId}&redirectUrl=${encodeURIComponent(redirectUrl)}`
    }} variant="outline" data-sentry-element="ConsumerCard" data-sentry-source-file="UnauthenticatedTrialFocusPaywall.component.tsx" />

      <CorporateCard apps={apps} url={`/shop/corporate?articleId=${articleId}&redirectUrl=${encodeURIComponent(redirectUrl)}`} variant="outline" data-sentry-element="CorporateCard" data-sentry-source-file="UnauthenticatedTrialFocusPaywall.component.tsx" />

      {product.pricePerQuarter !== null ? <PaywallTermsLink pricePerQuarter={product.pricePerQuarter} termsAndConditionsUrl={termsAndConditionsUrl} /> : null}
    </PaywallLayout>;
}